<template>
  <ig-page-base
    :is-boxed="true"
    :title="$t('pageTermsAndConditions.title')"
    class="page-terms"
    type="large"
  >
    <!-- eslint-disable -->
    <div class="content">
      <p>
        Este <strong>Termo e Condições Gerais (“Termo”)</strong> aplicam-se ao uso dos serviços
        oferecidos pela <strong>Integrai Tecnologia LTDA</strong> devidamente inscrita no CNPJ/MF
        sob o no 40.522.875/0001-78, gestora dos Serviços INTEGRAI, disponível para contratação
        através do Site https://integrai.com.br/, objeto deste Termo, doravante denominada INTEGRAI,
        a PESSOA FÍSICA OU JURÍDICA, maior e capaz, que tenha preenchido o Cadastro, cujos dados
        passam a ser parte integrante deste Termo, bem como que tenha "aceitado" eletronicamente
        todas as cláusulas do mesmo e todas as demais políticas disponíveis no Site, doravante
        denominado USUÁRIO.
      </p>

      <p>
        Os serviços disponíveis pela INTEGRAI no Site https://integrai.com.br/ serão regidos pelas
        cláusulas e condições abaixo.
      </p>

      <p>
        Ao aceitar eletronicamente o presente Termo, através do clique no botão “Aceito os Termos de
        Uso” da página de cadastro complementar a esta, o USUÁRIO estará automaticamente aderindo e
        concordando em se submeter integralmente a seus termos e condições e de qualquer de suas
        alterações futuras, além de aceitar as disposições das políticas do Site.
      </p>

      <div>
        <h4>CLÁUSULA 1ª - DAS DEFINIÇÕES</h4>

        <p>
          1.1 Para exata compreensão e interpretação dos direitos e obrigações previstos no presente
          Termo, são adotadas as seguintes definições:
        </p>

        <ul>
          <li>
            CADASTRO: ficha cadastral com os dados do USUÁRIO, obrigatoriamente preenchida com os
            dados ali determinados para a criação de um Login e uma Senha do USUÁRIO para a
            utilização da plataforma e dos Serviços da INTEGRAI. Com o mesmo CADASTRO, o USUÁRIO
            também deverá efetuar os pagamentos das MENSALIDADES dos Serviços INTEGRAI
          </li>
          <li>
            LOGIN: nome informado pelo USUÁRIO no ato do CADASTRO
          </li>
          <li>
            MENSALIDADE: valor devido pelo USUÁRIO em virtude da utilização dos Serviços INTEGRAI,
            conforme contratados;
          </li>
          <li>
            SENHA: sequência de letras e números escolhida pelo USUÁRIO, composta de no mínimo 8
            (seis) caracteres, a qual deverá ser previamente informada pelo USUÁRIO quando da sua
            visita ao Site de acesso aos Serviços INTEGRAI. A INTEGRAI ressalta que não terá acesso
            a esta SENHA. Portanto, se o USUÁRIO quiser recuperar seu acesso, este deverá enviar o
            pedido de recuperação de senha através do link “Esqueceu sua senha”, que enviará uma
            senha temporária para o email cadastrado do USUÁRIO para que este, a partir deste
            acesso, possa gerar uma nova SENHA diretamente no painel de controle da INTEGRAI. Sendo
            assim, com este procedimento, a SENHA poderá ser alterada a qualquer momento pelo
            USUÁRIO. Caso o USUÁRIO queira efetuar qualquer outra atividade relacionada com seu
            CADASTRO, tal como modificar alguma informação em seu CADASTRO, este deverá seguir os
            procedimentos descritos no Site da INTEGRAI e acessar os dados de seu perfil e efetuar
            as mudanças;
          </li>
          <li>
            Serviços INTEGRAI: plataforma prestadora dos serviços que possibilita ao USUÁRIO
            integrar diversas plataformas por ele contratados, de forma simples e rápida, através da
            ferramenta da INTEGRAI, automatizando os processos de cada uma dessas plataformas para
            facilitar o cotidiano do USUÁRIO nos diversos aspectos de seu negócio, com o objetivo
            final de diminuir o atrito e as dificuldades enfrentadas pelos USUÁRIOS a terem que
            configurar diferentes plataformas ou dos USUÁRIOS terem que manualmente alimentá- las
            com dados de uma com a outra;
          </li>
          <li>
            Site: portal eletrônico da INTEGRAI, por meio do qual o USUÁRIO poderá contratar e terá
            acesso aos Serviços INTEGRAI, conforme pacotes disponíveis no Site, mediante
            preenchimento de CADASTRO, informação de LOGIN e SENHA de acesso próprias.
          </li>
        </ul>
      </div>

      <div>
        <h4>CLÁUSULA 2ª - DO OBJETO</h4>

        <p>
          2.1. O INTEGRAI é uma plataforma que permite ao USUÁRIO, com ou sem muito conhecimento na
          área de tecnologia, optar por integrar diversas plataformas de controle por ele
          contratados, dentre elas, controle de gastos financeiros, meios de pagamentos eletrônicos,
          cálculo de frete, dentre outros, de forma simples e rápida, criando uma alimentação
          automática de dados entre as mesmas, economizando tempo e mão-de-obra técnica, através da
          contratação dos Serviços INTEGRAI, que conectará, a pedido do USUÁRIO, plataformas
          terceirizadas para se tornarem aptas a receber os conteúdos inseridos diretamente em suas
          aplicações, mediante pagamento da MENSALIDADE, conforme pacote contratado pelo USUÁRIO no
          Site.
        </p>

        <p>
          2.2. Através da contratação pelo Site da INTEGRAI, mediante Senha e Login, o USUÁRIO terá
          à sua disposição uma ferramenta que integra automaticamente as diversas plataformas
          utilizadas pelo USUÁRIO, os seus websites, bancos de dados e aplicativos, além de fornecer
          um monitoramento contínuo dos mesmos, de forma descomplicada. Portanto, em razão da
          própria natureza dos Serviços INTEGRAI, este não terá qualquer acesso às senhas e logins
          do USUÁRIO nas diversas plataformas, que deverão ser acessadas pelo USUÁRIO em momento
          oportuno, salvo em casos em que a própria integração necessita da mesma, situação em que o
          USUÁRIO poderá remover e adicionar estes dados quando quiser, já que estas plataformas
          serão geridas e mantidas única e exclusivamente pelo USUÁRIO, conforme instruído pela
          INTEGRAI no momento da integração. A INTEGRAI só pede que o USUÁRIO mantenha as mesmas
          CHAVES DE ACESSO (tokens) que recebeu no momento da contratação dos Serviços INTEGRAI e
          sua integração, para que os Serviços INTEGRAI não sejam interrompidos. Dessa forma, o
          USUÁRIO declara que está ciente que é o único a possuir acesso a estas CHAVES de ACESSO e
          nada na prestação dos Serviços INTEGRAI faz com que esta tenha acesso aos mesmos.
        </p>

        <p>
          2.2.1. Caso o USUÁRIO não tenha mais interesse em manter uma integração através dos
          Serviços INTEGRAI, a INTEGRAI alerta desde já que no momento que o USUÁRIO confirmar a
          remoção de dita integração, todo e qualquer dado histórico de troca de informações entre
          as ferramentas que estejam arquivadas nos servidores da INTEGRAI poderão ser apagados, sem
          qualquer possibilidade de recuperação. As informações que já estejam constando em cada uma
          das diversas plataformas (resultantes de uma integração) permanecerão acessíveis pelo
          mesmo tempo enquanto o USUÁRIO tiver conta nestas plataformas.
        </p>

        <p>
          2.2.2. Agora caso o USUÁRIO queira cancelar a sua MENSALIDADE da INTEGRAI, todas as
          atividade que o USUÁRIO teria acesso serão automaticamente desativadas, permanecendo
          somente com acesso a integrações que fazem parte do plano gratuito da época. Dessa forma,
          o USUÁRIO passará a ser considerado pela equipe da INTEGRAI como um USUÁRIO do plano
          gratuito. O USUÁRIO somente poderá reiniciar as demais atividades de integrações quando o
          USUÁRIO renovar a sua MENSALIDADE. A INTEGRAI desde já clarifica que nenhuma automatização
          será feita durante este período de cancelamento ou não renovação, não sendo portanto
          responsável por nenhum dado perdido, e nem pela recuperação dos mesmos.
        </p>

        <p>
          2.2.3. A INTEGRAI ressalta para todos os fins que não tem e nunca terá qualquer acesso aos
          dados armazenados pelo USUÁRIO nos servidores da INTEGRAI, após a integração, mesmo em
          caso de resilição dos seus Serviços INTEGRAI.
        </p>

        <p>
          2.2.4. Caso o USUÁRIO fique em mora mais do que 15 (quinze) dias a contar da notificação
          da INTEGRAI, o USUÁRIO será automaticamente reenquadrado como USUÁRIO do plano gratuito,
          na forma prevista no item 2.2.2 acima, sem qualquer notificação prévia além daquela da
          INTEGRAI informando dos problemas de processamento do pagamento da MENSALIDADE. As
          integrações serão somente reestabelecidas após o USUÁRIO quitar qualquer MENSALIDADE
          pendente.
        </p>

        <p>
          2.3. Em que pese ser passível de utilização a partir de qualquer parte do mundo, o USUÁRIO
          declara-se ciente e concordando que, independentemente do local de onde estejam sendo
          acessados os serviços, a relação entre o USUÁRIO e a INTEGRAI estará sempre, e em qualquer
          hipótese, sujeita à legislação brasileira.
        </p>

        <p>
          2.4. Após a escolha do pacote de Serviços INTEGRAI à disposição no Site, a INTEGRAI
          redirecionará o USUÁRIO para que este possa efetuar o pagamento do valor pedido pelo
          pacote. Com a confirmação do pagamento do pacote, o pedido especificado pelo USUÁRIO será
          processado pela INTEGRAI e os Serviços INTEGRAI serão implementados.
        </p>

        <p>
          2.4.1. A INTEGRAI, buscando respeitar a legislação aplicável ao consumidor com relação aos
          Serviços INTEGRAI, efetuará o débito da compra na conta indicada pelo USUÁRIO e este terá
          o prazo de 07 (sete) dias, contados da realização do pagamento dos Serviços INTEGRAI para
          exercer seu direito de arrependimento.
        </p>

        <p>
          2.4.2. Caso, em até 07 (sete) dias após a realização do pagamento dos Serviços INTEGRAI, a
          INTEGRAI não tenha recebido nenhuma notificação de arrependimento do USUÁRIO através do
          email cancelamento@integrai.com.br, entender-se-á que o USUÁRIO renunciou tacitamente ao
          seu direito de arrependimento e a quaisquer direitos referentes ao presente, passado e
          futuro, relativos a fatos, obrigações e responsabilidades, direta ou indiretamente
          relacionados aos Serviços INTEGRAI, nada mais tendo a reclamar da INTEGRAI, seja a que
          título for, judicial ou extrajudicialmente, em que tempo for. Neste sentido, o USUÁRIO
          desde já concorda que os procedimentos de arrependimento descritos no presente Termo serão
          os seus únicos e exclusivos remédios para ser reembolsado pelo valor pago pelos Serviços
          INTEGRAI.
        </p>

        <p>
          2.4.3. Caso o USUÁRIO comunique a INTEGRAI, no prazo indicado na Cláusula 2.4.2 acima, o
          seu arrependimento, a INTEGRAI iniciará o procedimento de reembolso dos valores pagos, que
          será efetuado em até 05 (cinco) dias úteis, contados do comunicado.
        </p>

        <p>
          2.5. O INTEGRAI facilitará a realização do pagamento dos pacotes de Serviços INTEGRAI
          adquiridos pelo USUÁRIO, intermediando a troca de informações dos USUÁRIOS com o Meio de
          Pagamento escolhido pela INTEGRAI para realizar a cobrança (“Meio de Pagamento”), desde
          que exista a anuência do USUARIO, mediante o aceite do presente Termo. Tais informações
          poderão se referir àqueles disponibilizadas no CADASTRO, tais como o endereço do USUÁRIO,
          o email, ou qualquer outra informação que seja disponibilizada pelo USUÁRIO para o Site da
          INTEGRAI. Ainda neste sentido, o USUÁRIO fica desde já ciente que a INTEGRAI apenas é um
          intermediário entre o USUÁRIO e os serviços do Meio de Pagamento para a realização dos
          pagamentos devidos. Neste caso, a INTEGRAI compartilhará informações sobre as transações
          comerciais com o Meio de Pagamento e permitirá que os USUÁRIOS possam, dentro do ambiente
          indicado de uso da INTEGRAI, autorizar o Meio de Pagamento a realizar os débitos, créditos
          e transferências de fundos relevantes, inclusive para o pagamento das multas aplicáveis,
          conforme descrito no presente Termo. Portanto, mediante o aceite do presente Termo, o
          USUÁRIO deliberadamente aceita o uso e fornecimento de suas informações pela INTEGRAI para
          o Meio de Pagamento para a correta prestação dos Serviços INTEGRAI.
        </p>

        <p>
          2.6. As comunicações e as transações comerciais entre os USUÁRIOS serão acompanhadas ou
          mediadas pela INTEGRAI, com o objetivo de zelar pelo bom atendimento, pela prevalência da
          boa-fé e dos princípios constitucionais de lealdade e segurança nas comunicações entre os
          USUÁRIOS.
        </p>
      </div>

      <div>
        <h4>CLÁUSULA 3ª - DO CADASTRO E USO DOS SERVIÇOS INTEGRAI</h4>

        <p>
          3.1. Para contratação dos Serviços INTEGRAI, o USUÁRIO deverá acessar o Site, preencher o
          CADASTRO disponibilizado para a habilitação dos Serviços INTEGRAI, informando a INTEGRAI
          todos os dados exigidos, e responsabilizando-se o USUÁRIO civil e criminalmente pela
          veracidade das informações, inclusive perante terceiros, obrigando-se também a manter seus
          dados atualizados. O USUÁRIO deverá ainda informar obrigatoriamente um endereço de e-mail
          de uso único e exclusivo do USUÁRIO. Neste sentido, a INTEGRAI não efetua qualquer
          checagem de informações de nenhum USUÁRIO que deseje utilizar os serviços disponibilizados
          no Site no ato do CADASTRO, nem realiza qualquer investigação sobre as informações e dados
          armazenados nos servidores, com exceção do previsto nas Cláusulas 3.2, 3.3 e 3.3.1. Desta
          forma, a INTEGRAI não se responsabiliza, de nenhuma maneira e em nenhuma hipótese, pela
          utilização indevida dos Serviços INTEGRAI.
        </p>

        <p>
          3.1.1. O LOGIN e a SENHA que dão acesso ao CADASTRO são de uso pessoal e intransferível do
          USUÁRIO, razão pela qual a INTEGRAI não se responsabiliza por eventual manipulação não
          autorizada dessas informações e dados por terceiros, e devendo, portanto, o USUÁRIO tomar
          todas as medidas necessárias para manter em sigilo as referidas informações. Ressalta-se
          que este LOGIN e SENHA são distintos dos logins e senhas do USUÁRIO nas diversas
          plataformas que deseja integrar ao contratar os Serviços INTEGRAI, que são acessados pelo
          USUÁRIO conforme instruído pela INTEGRAI para a correta integração. Ressaltamos que a
          única recomendação é que se o USUÁRIO efetuar a troca destas SENHAS, cuide para não trocar
          as CHAVES de ACESSO. Caso o USUÁRIO reinicie o sistema, uma nova integração dos Serviços
          INTEGRAI será necessária, podendo inclusive haver perda de alguns dados, conforme definido
          no presente Termo, ficando a INTEGRAI este isento de qualquer responsabilidade ou
          ressarcimento em caso de não respeitada esta recomendação.
        </p>

        <p>
          3.1.2. O USUÁRIO compromete-se a notificar a INTEGRAI imediatamente, mediante apresentação
          da reclamação através do email contato@integrai.com.br, a respeito de qualquer uso não
          autorizado de sua conta, bem como o acesso não autorizado por terceiros a mesma. O USUÁRIO
          será o único responsável pelas operações efetuadas em sua conta, uma vez que o acesso à
          mesma só será possível mediante a aposição da SENHA, cujo conhecimento é exclusivo do
          USUÁRIO.
        </p>

        <p>
          3.1.3. Os Serviços INTEGRAI estarão a disposição do USUÁRIO 24 (vinte e quatro) horas por
          dia, 7 (sete) dias por semana, com exceção de manutenção regulares que serão previamente
          notificadas pela INTEGRAI ao USUÁRIO através de comunicações diretamente no sistema de
          administração do CADASTRO do USUÁRIO.
        </p>

        <p>
          3.2. O INTEGRAI se reserva o direito de utilizar todos os meios válidos e possíveis para
          identificar seus USUÁRIOS, portanto poderá a INTEGRAI, a qualquer momento, à seu critério,
          solicitar cópias de documentos do USUÁRIO, de forma a garantir a veracidade dos dados
          pessoais do USUÁRIO. Nestes casos, a INTEGRAI poderá suspender o fornecimento de Serviços
          INTEGRAI, até o recebimento dos documentos solicitados, que deverão ser enviados em até 72
          (setenta e duas) horas, contadas da notificação, ficando isento de qualquer
          responsabilidade ou ressarcimento ao pretendente. Caso algum dado seja verificado pela
          INTEGRAI como inconsistente com os documentos apresentados, o USUÁRIO terá o prazo de 2
          (dois) dias úteis para efetuar as devidas correções, sob pena de ter o seu acesso aos
          Serviços INTEGRAI bloqueados, até a regularização do CADASTRO, podendo inclusive cancelar
          definitivamente o referido CADASTRO e rescindir os Serviços INTEGRAI, se assim entender
          necessário à proteção dos interesses da INTEGRAI e seus USUÁRIOS, sem prejuízo de outras
          medidas que entender necessárias e oportunas.
        </p>

        <p>
          3.2.1. Se for rescindido o CADASTRO e os Serviços INTEGRAI, os procedimentos previstos na
          Cláusula 2.2.1 acima serão respeitados, sendo que a cobrança dos valores constantes como
          MENSALIDADE não serão interrompidos até a finalização dos Serviços INTEGRAI pelo USUÁRIO.
        </p>

        <p>
          3.3. As informações fornecidas pelo USUÁRIO em seu CADASTRO (excluído todo e qualquer dado
          armazenado nas diversas plataformas) serão de uso confidencial e exclusivo da INTEGRAI e
          seus parceiros, e somente serão fornecidas nos casos previstos pela Lei ou quando
          constatada(s) fraude(s), danos e prejuízos a INTEGRAI, como por exemplo: falsidade
          ideológica (utilização de nomes e documentos falsos), ou incorreta utilização dos Serviços
          INTEGRAI, hipótese em que as informações do fraudador (nome, endereço, IP etc) poderão ser
          fornecidas às autoridades responsáveis (incluindo-se aí as Delegacias Especializadas de
          Repressão a Crimes contra a Informática e Fraudes Eletrônicas).
        </p>

        <p>
          3.3.1. Nesse sentido, e sem prejuízo de outras medidas, a INTEGRAI poderá advertir,
          suspender ou cancelar, temporária ou definitivamente o CADASTRO de um USUÁRIO, a qualquer
          tempo, iniciando as ações legais cabíveis e/ou suspendendo a prestação de seus Serviços
          INTEGRAI se: a) o USUÁRIO não cumprir qualquer dispositivo deste Termo e demais políticas
          da INTEGRAI; b) se descumprir com seus deveres de USUÁRIO; c) se praticar atos
          fraudulentos ou dolosos, dentre outros; d) se não puder ser verificada a identidade do
          USUÁRIO ou qualquer informação fornecida por ele esteja incorreta. Sem prejuízo do acima,
          se ficar verificado que o USUÁRIO se utilizou dos Serviços INTEGRAI para praticar
          atividades suspeitas, e não tenha apresentado documentação comprobatória da legalidade de
          suas atividades, além de suspender todas as integrações, independente de serem parte dos
          planos gratuitos e/ou pagos, o USUÁRIO será descontado diretamente do seu cartão de
          crédito e/ou de qualquer outra forma de pagamento selecionada no ato de contratação das
          integrações não pertecentes ao plano gratuito, em uma parcela ou em diversas parcelas, até
          a quitação integral de qualquer indenização que a INTEGRAI tenha que efetuar em
          decorrência dos atos do referido USUÁRIO.
        </p>

        <p>
          3.4. Os Serviços INTEGRAI estão disponíveis apenas para as pessoas que tenham capacidade
          legal para utilizá-las, portanto, pessoas que não gozem dessa capacidade, inclusive
          menores de idade, ou pessoas que tenham sido inabilitadas do Site, temporária ou
          definitivamente, por qualquer infração ao presente Termo, estão advertidas das sanções
          legais do Código Civil, notadamente, dos artigos 166, inciso I, 171, inciso I e 180 da lei
          n° 10.406/02.
        </p>

        <p>
          3.5. O USUÁRIO expressamente reconhece e concorda com a MENSALIDADE cobrada pela INTEGRAI,
          como remuneração pela utilização dos Serviços INTEGRAI, que serão pagos mediante a forma
          escolhida pelo USUÁRIO no ato da contratação do plano identificado pela INTEGRAI, conforme
          a sua usuabilidade.
        </p>

        <p>
          3.6. O apelido que o USUÁRIO utiliza no Site não poderá guardar semelhança com o nome
          INTEGRAI. Também serão eliminados apelidos e imagens considerados ofensivos, ou de origem
          ilícita, bem como os que contenham dados pessoais do USUÁRIO ou alguma URL ou endereço
          eletrônico. A INTEGRAI se reserva o direito de recusar qualquer solicitação de CADASTRO e
          de cancelar um CADASTRO previamente aceito, desde que em desacordo com as políticas e
          regras do presente Termo.
        </p>

        <p>
          3.7. A não utilização do CADASTRO pelo USUÁRIO, definida como a ausência de qualquer
          acesso ao mesmo, pelo prazo de 01 (um) ano, ensejará o encerramento do referido CADASTRO,
          ficando a INTEGRAI isenta de qualquer responsabilidade ou ressarcimento em virtude deste
          cancelamento de CADASTRO.
        </p>

        <p>
          3.8. O USUÁRIO será responsável pelo uso dos serviços providos no presente Termo,
          observando todas as leis, decretos e regulamentos nacionais, estaduais e municipais
          aplicáveis e em vigor, as Normas de Segurança e Privacidade e as Políticas publicadas e
          divulgadas pela INTEGRAI e por seus parceiros comerciais, constantes em seu Site.
        </p>

        <p>
          3.9. A INTEGRAI não será responsabilizado por erros, interrupções, mal funcionamentos,
          falhas de segurança, falhas nos servidores ou nos sistemas operacionais, atrasos ou outras
          imperfeições que possam surgir nos Serviços INTEGRAI, inclusive pela qualidade e
          estabilidade de servidores terceirizados, em virtude ou não da utilização de aplicativos
          de propriedade alheia a INTEGRAI, integrados pela INTEGRAI, ou em virtude dos próprios
          Serviços INTEGRAI, que são possíveis de ocorrer em se tratando de informática, não
          garantindo, a INTEGRAI, de nenhuma forma, a prestação dos Serviços INTEGRAI de forma
          ininterrupta e/ou isenta de erros.
        </p>

        <p>
          3.9.1. Da mesma forma, não fazem parte dos serviços da INTEGRAI a configuração de dados em
          ferramentas de terceiros. Portanto, a INTEGRAI não se responsabiliza por configurações de
          dados realizadas de forma incorreta pelos USUÁRIOS em ferramentas de terceiros. Se a
          INTEGRAI verificar 3 (três) erros consecutivos na execução de uma determinada
          automatização, esta se reserva no direito de desativá-la até que a automatização seja
          corretamente configurada pelo USUÁRIO, sem prejuízo da cobrança das MENSALIDADES
          pendentes, caso a automatização se configure como não pertencente ao plano gratuito da
          INTEGRAI. Independente do acima, a INTEGRAI poderá rescindir a prestação de seus serviços
          na forma prevista no item 8.2 abaixo.
        </p>

        <p>
          3.10. O USUÁRIO será integralmente responsável por certificar-se de que a configuração de
          seu equipamento está em pleno acordo com os requisitos mínimos de segurança, como
          antivírus sempre atualizados, necessários ao acesso do Site e os Serviços INTEGRAI,
          estando este último livre e isento de qualquer responsabilidade decorrente da não
          observância do disposto nesta cláusula.
        </p>
      </div>

      <div>
        <h4>CLÁUSULA 4ª - DA MENSALIDADE DEVIDA PELO USUÁRIO</h4>

        <p>
          4.1. Em razão da utilização dos Serviços INTEGRAI, o USUÁRIO pagará mensalmente a
          INTEGRAI, por meio de boleto bancário ou cartão de crédito, o valor previsto na página de
          planos da INTEGRAI, de acordo com a quantidade de integrações realizadas, cujo vencimento
          ocorrerá até a data acordada no CADASTRO.
        </p>

        <p>
          4.1.1. O INTEGRAI efetuará 04 (quatro) tentativas de cobrança no cartão de crédito
          informado pelo USUÁRIO, em um período de 10 (dez) dias. Se a INTEGRAI não lograr efetuar a
          devida cobrança da MENSALIDADE, a suspensão das integrações atreladas ao CADASTRO do
          USUÁRIO no plano pago será realizada automaticamente e o atraso no pagamento da
          MENSALIDADE prevista no caput desta Cláusula poderá implicar na incidência de multa de 10%
          (dez por cento), além de correção monetária e juros de mora de 1% (um por cento) ao mês,
          facultando-se a INTEGRAI a suspensão das integrações atreladas ao CADASTRO do USUÁRIO e o
          reenquadramento do USUÁRIO como pertecente ao plano gratuito até que este quite os valores
          pendentes. A INTEGRAI se reserva o direito de tomar as medidas judiciais e extrajudiciais
          pertinentes para receber os valores devidos.
        </p>

        <p>
          4.1.2. Sem prejuízo do disposto no parágrafo anterior, a INTEGRAI poderá dar o presente
          Termo por rescindido, suspendendo as integrações que não pertecem ao plano gratuito da
          INTEGRAI, caso o USUÁRIO permaneça inadimplente por mais de 07 (sete) dias, sendo que a
          INTEGRAI poderá ainda, a seu exclusivo critério, e independente de qualquer aviso,
          comunicar dita inadimplência aos órgãos de proteção de crédito, que promoverão a inscrição
          do USUÁRIO em seus cadastros (SERASA/SPC).
        </p>

        <p>
          4.1.3. A MENSALIDADE prevista no caput desta cláusula poderá ser reajustada
          automaticamente a cada 12 (doze) meses, contados do ato do CADASTRO, de acordo com o IGP-M
          ou, na sua ausência, com o índice que vier a substituí-lo, independentemente de prévia
          notificação ao USUÁRIO.
        </p>

        <p>
          4.2. Caso o USUÁRIO não cancele seu CADASTRO, a MENSALIDADE pendente será ainda devida,
          mesmo que as integrações sejam suspensas e o CADASTRO do USUÁRIO seja reenquadrado como
          pertencente ao plano gratuito.
        </p>

        <p>
          4.3. Caso sejam criados novos tributos ou alteradas as condições de cálculo e/ou cobrança
          de tributos já existentes, que venham a impactar nos valores de remuneração vigentes, os
          custos resultante de tal impacto poderão ser repassados ao USUÁRIO e somados à remuneração
          vigente, de forma a restabelecer o equilíbrio econômico-financeiro da prestação dos
          serviços.
        </p>

        <p>
          4.4. Após o enquadramento do USUÁRIO em um dos planos de acordo com as integrações
          realizadas, o USUÁRIO poderá efetuar o pagamento da MENSALIDADE indicada para acesso
          contínuo às integrações já realizadas diretamente no ambiente da INTEGRAI.
        </p>

        <p>
          4.4.1. A INTEGRAI, buscando respeitar a legislação aplicável ao consumidor com relação ao
          direito de arrependimento dos serviços adquiridos por USUÁRIOS no ambiente da INTEGRAI,
          que é de 07 (sete) dias a contar do CADASTRO, a INTEGRAI outorga um período de 7 (sete)
          dias de teste gratuito de todas as integrações disponíveis na Plataforma da INTEGRAI
          (“Período de Teste”). Qualquer dado que o USUÁRIO inserir para serem posteriormente
          automatizados pela INTEGRAI e qualquer personalização efetuada neles durante o período de
          teste irá se perder, permanentemente, a menos que: (i) o USUÁRIO adquira o Plano indicado
          para o nível de utilização de integrações, insira os seus dados de cartão de crédito, ou
          outra forma de pagamento disponível pela INTEGRAI, e efetue o pagamento de uma
          MENSALIDADE, para as mesmas integrações da INTEGRAI que tenha interesse. Após 30 (trinta)
          dias contados a partir do fim do Período de Teste, todos os dados do USUÁRIO poderão ser
          excluídos.
        </p>

        <p>
          4.4.2. Caso, durante o Período de Teste, ou mesmo logo após o seu encerramento, o USUÁRIO
          contratar os Planos mais adequado ao seu nível de utilização de integrações e efetuar o
          pagamento da MENSALIDADE, entender-se-á que o USUÁRIO renunciou tacitamente ao seu direito
          de arrependimento e a quaisquer direitos referentes ao presente, passado e futuro,
          relativos a fatos, obrigações e responsabilidades, direta ou indiretamente relacionados ao
          Período de Teste das integrações da INTEGRAI, nada mais tendo a reclamar da INTEGRAI, seja
          a que título for, judicial ou extrajudicialmente, em que tempo for.
        </p>
      </div>

      <div>
        <h4>CLÁUSULA 5ª - DAS MODIFICAÇÕES NO PRESENTE TERMO</h4>

        <p>
          5.1. O INTEGRAI poderá alterar, a qualquer tempo, as condições do presente Termo, visando
          seu aprimoramento e melhoria dos serviços prestados. As novas condições entrarão em vigor
          10 (dez) dias após sua publicação no Site. Sendo assim, caso o USUÁRIO não concorde com as
          modificações publicadas, este deverá imediatamente cessar o uso dos Serviços INTEGRAI,
          proceder com o cancelamento de suas informações e dados, mediante envio ao email
          contato@integrai.com.br, conforme procedimentos descritos no presente Termo, sob pena de
          responder pelos danos gerados a terceiros e a INTEGRAI pela retirada tardia dos dados
          armazenados em seus servidores. Neste caso, o vínculo contratual deixará de existir. No
          entanto, não havendo manifestação no prazo acima estipulado, entender-se-á que o USUÁRIO
          aceitou tacitamente as novas condições do Termo e continuará vinculando as partes.
        </p>
      </div>

      <div>
        <h4>CLÁUSULA 6ª - DOS PRODUTOS E PRÁTICAS PROIBIDOS</h4>

        <p>
          6.1. Estão expressamente vedadas as seguintes práticas com os Serviços INTEGRAI, que
          contenham relação ou que façam apologia a: (i) atividades ilícitas; (ii) Narcóticos; (iii)
          propriedades roubadas, furtadas ou subtraídas, ou de qualquer origem ilícita, tais como
          contrabando, falsificações ou adulterações; (iv) Produtos e serviços relacionados à
          prostituição ou similares; (v) Produtos e serviços que promovam a violência e/ou
          discriminação, baseada em questões de raça, sexo, religião, nacionalidade, orientação
          sexual ou de qualquer outro tipo; (vi) Produtos e serviços que violem as leis sobre a
          propriedade intelectual, tais como cópias não autorizadas, de imagens ou textos sem
          autorização do autor, sejam elas marcas, símbolos, réplicas e/ou falsificações; (vii)
          Produtos e serviços relacionados a loterias, rifas e jogos de azar; e (viii) quaisquer
          outros produtos e serviços vedados na legislação vigente.
        </p>

        <p>
          6.1.1. Neste sentido, é responsabilidade exclusiva do USUÁRIO velar pela legalidade de
          suas atividades e dados e a INTEGRAI não assume nenhuma responsabilidade pela existência
          deles nos Serviços INTEGRAI, podendo excluir os USUÁRIOS que não cumpram com as restrições
          descritas no presente Termo.
        </p>

        <p>
          6.2. Caso a INTEGRAI receba denúncia, ou suspeite que algum USUÁRIO esteja violando alguma
          condição especifica do presente Termo ou que seja considerada uma atividade ilícita, além
          das medidas de proteção já descriminadas no presente Termo, tal como a suspensão ou o
          cancelamento do referido CADASTRO, os dados do USUÁRIO infrator serão encaminhados às
          autoridades correspondentes, para que estas tomem as medidas judiciais cabíveis.
        </p>
      </div>

      <div>
        <h4>CLÁUSULA 7ª - DA PRIVACIDADE DA INFORMAÇÃO</h4>

        <p>
          7.1. A plataforma prestadora dos Serviços INTEGRAI utiliza-se do aplicativo de “cookies”
          (dados no computador/celular do USUÁRIO) para permitir sua correta identificação, além de
          melhorar a qualidade das informações oferecidas em seu portal para os USUÁRIOS. O uso
          regular de cookies é uma prática aceitável na indústria, pois permite o armazenamento de
          informações importantes, como, por exemplo, os acessos do USUÁRIO na plataforma prestadora
          dos Serviços INTEGRAI, para posteriormente não ser apresentado um conteúdo repetido ou
          ultrapassado. Se não for a vontade do USUÁRIO receber cookies, ou o mesmo desejar uma
          notificação prévia antes de seu uso, ele deverá programar, a seu critério, seu Web browser
          com estas opções, caso seu browser disponha do recurso.
        </p>

        <p>
          7.2. Além das informações pessoais fornecidas, a plataforma prestadora dos Serviços
          INTEGRAI tem a capacidade tecnológica de recolher outras informações técnicas, como o
          endereço de protocolo de Internet do USUÁRIO, o sistema operacional do computador ou do
          celular, o tipo de browser e o endereço de websites de referência.
        </p>

        <p>
          7.3. Conforme já determinado anteriormente, a INTEGRAI não fornecerá as informações do
          USUÁRIO a terceiros sem prévia autorização do mesmo, com exceção de casos que a INTEGRAI
          já possui a autorização para o seu compartilhamento, conforme o presente Termo, ou quando
          são necessários para responder às solicitações ou perguntas de entidades governamentais,
          ou nos casos onde, de boa fé, a INTEGRAI entender que é necessária a sua divulgação, com o
          propósito de responder às reclamações que os dados armazenados infringe direitos de
          terceiros, ou seja, necessária para a proteção de direitos, propriedades e/ou segurança da
          INTEGRAI, de seus USUÁRIOS e/ou do público em geral.
        </p>

        <p>
          7.4. A INTEGRAI poderá, a seu critério, fazer uso das informações armazenadas nos seus
          bancos de dados, conforme descrito acima, por um prazo razoável, sem que exceda os
          requisitos ou limitações legais, para dirimir quaisquer disputas, solucionar problemas e
          garantir os direitos da INTEGRAI, assim como os termos e condições presentes na Política
          de Privacidade.
        </p>

        <p>
          7.5. A INTEGRAI deverá também, a seu critério, examinar os CADASTROS e algumas informações
          pessoais armazenadas em seus bancos de dados com o propósito de identificar USUÁRIOS que
          estejam infringindo as políticas de segurança da INTEGRAI, para fins legais e/ou de
          segurança. Em outra hipótese, se a INTEGRAI for obrigada por lei, ordem judicial ou outro
          processo legal, a divulgar alguma informação pessoal do USUÁRIO, não hesitará em cooperar
          com estes agentes. Assim, por meio deste instrumento, o USUÁRIO autoriza a INTEGRAI a
          divulgar estas informações pessoais para atender aos fins acima indicados.
        </p>

        <p>
          7.6. As informações cedidas pelo USUÁRIO e registradas devido ao uso do sistema (com
          exceção ao conteúdo de mensagens pessoais) servirão como insumos para o mapeamento de
          informações de mercado e formação de estatísticas da INTEGRAI. Através do cadastramento,
          uso e fornecimento de informações a INTEGRAI, o USUÁRIO deliberadamente aceita o presente
          Termo e as condições previstas na Política de Privacidade sobre o uso de suas informações.
          No entanto, a INTEGRAI reforça que não terá qualquer acesso aos dados e informações dos
          USUÁRIOS nas diversas plataformas que serão integradas pela INTEGRAI, por serem integradas
          de forma criptografadas pelas próprias plataformas, sendo destas a inteira
          responsabilidade por estes dados.
        </p>

        <p>
          7.7. Informações adicionais coletadas pela INTEGRAI através da análise da navegação de
          cada USUÁRIO e que não o torne identificável pessoalmente são de propriedade exclusiva da
          INTEGRAI, que pode usar essas informações do modo que melhor julgar apropriada. Além
          disso, as informações fornecidas são usadas para: (i) administrar a conta dos USUÁRIOS a
          fim de customizar cada vez mais os Serviços INTEGRAI; e (ii) comunicar novidades e
          atualizações.
        </p>

        <p>
          7.8. O USUÁRIO que não concordar com o presente Termo e proceder com o cancelamento de seu
          CADASTRO, removerá seus dados pessoais do CADASTRO para futuras consultas, mas alguns
          dados continuarão armazenados, de forma a atender as obrigações legais da INTEGRAI.
          Ressalta-se novamente que a INTEGRAI não possui acesso algum aos dados e informações
          armazenados pelo USUÁRIO na INTEGRAI, com exceção dos dados pessoais do USUÁRIO, conforme
          descrito anteriormente.
        </p>

        <p>
          7.9. Não é permitida a utilização de nenhum dispositivo, software ou outro recurso que
          venha a interferir nas atividades e operações dos Serviços INTEGRAI, bem como nas contas
          ou seus bancos de dados. Qualquer intromissão, tentativa de, ou atividade que viole ou
          contrarie as leis de direito de propriedade intelectual e/ou as proibições estipuladas
          neste Termo, inclusive tentativas de engenharia reversa, tornarão o responsável passível
          das ações legais pertinentes, bem como das sanções aqui previstas, sendo ainda responsável
          pelas indenizações por eventuais danos causados.
        </p>

        <p>
          7.10. Em caso de dúvidas sobre a proteção a dados pessoais, ou para obter maiores
          informações sobre dados pessoais e os casos nos quais poderá ser quebrado o sigilo de que
          trata esta cláusula, consultar a seção Contato do Site da INTEGRAI.
        </p>
      </div>

      <div>
        <h4>CLÁUSULA 8ª - DO PRAZO E DA VIGÊNCIA</h4>

        <p>
          8.1. O presente Termo possui prazo indeterminado, entrando em vigor na data de seu aceite
          eletrônico. O USUÁRIO poderá rescindir a prestação dos Serviços INTEGRAI e proceder com os
          procedimentos de rescisão aqui dispostos a qualquer momento, mediante comunicação prévia e
          expressa à outra parte no prazo de 30 (trinta) dias, desde eventuais débitos existentes
          (custos, despesas, encargos operacionais) ou eventuais indenizações decorrentes de lesão a
          terceiros por conta do mal-uso dos Serviços INTEGRAI sejam quitados. No entanto, qualquer
          cancelamento não ensejará o reembolso de qualquer MENSALIDADE já devida.
        </p>

        <p>
          8.2. A INTEGRAI poderá rescindir o presente Termo e excluir algum USUÁRIO de sua base de
          dados e de sua plataforma, de pleno direito, independentemente de qualquer notificação ou
          interpelação, judicial ou extrajudicial, nas seguintes hipóteses: (a) pela quebra de
          qualquer de suas disposições, de forma a impedir a continuidade da execução do objeto do
          mesmo; (b) se o USUÁRIO, de qualquer forma, comprometer a imagem pública da INTEGRAI e/ou
          de qualquer empresa associada a esta na prestação dos Serviços INTEGRAI; (c) se o USUÁRIO
          utilizar de práticas que desrespeitem a lei, a ordem pública, a moral, os bons costumes
          ou, ainda, o presente Termo e a política de segurança e privacidade; (d) se a INTEGRAI
          verificar 3 (três) erros consecutivos na execução de uma determinada automatização, sem
          que a mesma seja corretamente configurada pelo USUÁRIO; e (e) pelo não pagamento de uma ou
          mais das MENSALIDADES e/ou outras remunerações devidas.
        </p>
      </div>

      <div>
        <h4>CLÁUSULA 9ª - DAS RESPONSABILIDADES</h4>

        <p>
          9.1. A INTEGRAI não possui qualquer ingerência sobre os dados armazenados pelos USUÁRIOS
          nos servidores, não gerando a aceitação do presente Termo qualquer contrato de sociedade,
          de mandato, franquia ou relação de trabalho entre a INTEGRAI e o USUÁRIO. No entanto, a
          INTEGRAI mantem um controle estrito com relação às transações de seus USUÁRIOS e os dados
          armazenados em suas dependências, periodicamente avaliando os seus comportamentos e
          retirando de sua base quaisquer USUÁRIOS que não atendam aos padrões de ética e boa-fé da
          INTEGRAI ou que tenham sido objeto de denúncia de terceiros. Neste sentido, em nenhum
          caso, a INTEGRAI será responsável pelo lucro cessante ou por qualquer outro dano e/ou
          prejuízo que o USUÁRIO possa sofrer devido ao armazenamento incorreto dos dados nos
          servidores, em virtude dos mesmos serem armazenados em plataformas terceirizadas,
          contratados pelo USUÁRIO e integrados pela INTEGRAI, conforme pacote de serviços
          escolhido.
        </p>

        <p>
          9.2. A INTEGRAI não se responsabiliza pela qualidade, estado, integridade ou legitimidade
          dos dados armazenados pelos USUÁRIOS nos servidores, assim como pela capacidade para
          contratar dos USUÁRIOS ou pela veracidade dos dados pessoais por eles inseridos em seus
          CADASTROS. O INTEGRAI também não outorga garantia por vícios ocultos ou aparentes nos
          dados armazenados pelos USUÁRIOS, sendo o armazenamento pelo USUÁRIO feito por sua própria
          conta e risco, já que as plataformas são de propriedade de terceiros, alheios aa INTEGRAI
          e contratados diretamente pelo USUÁRIO. Neste sentido, se a INTEGRAI ou qualquer entidade
          governamental verificar que condutas proibidas estão sendo praticadas, a INTEGRAI não
          hesitará em cooperar com as autoridades que exijam informações e dados pessoais, com o
          objetivo de identificar tais USUÁRIOS. Assim, por meio deste Termo, o USUÁRIO autoriza
          desde já, de forma revogável e irretratável, a INTEGRAI a divulgar suas informações
          pessoais para atender aos fins acima indicados.
        </p>

        <p>
          9.3. Em caso de interpelação judicial que tenha como réu a INTEGRAI, cujos fatos fundem-se
          em ações do USUÁRIO, este será chamado ao processo devendo arcar com todos os ônus que daí
          decorram, nos termos do artigo 70, III do Código de Processo Civil. Da mesma forma, o
          USUÁRIO indenizará a INTEGRAI, suas filiais, empresas controladas ou controladoras,
          diretores, administradores, colaboradores, representantes, fornecedores e empregados por
          qualquer demanda promovida por outros USUÁRIOS ou terceiros decorrentes de suas atividades
          no Site ou por seu descumprimento do presente Termo ou pela violação de qualquer lei ou
          direitos de terceiros, incluindo honorários de advogados.
        </p>

        <p>
          9.4. O INTEGRAI não se responsabiliza pelas obrigações tributárias que recaiam sobre as
          atividades dos USUÁRIOS que se utilizam dos Serviços INTEGRAI.
        </p>

        <p>
          9.5. O INTEGRAI não se responsabiliza por qualquer dano, prejuízo, perda ou lucro cessante
          do USUÁRIO causado por condutas de terceiros, caso fortuito ou força maior, conforme
          definido na legislação vigente, ou por qualquer outro evento alheio ao controle da
          INTEGRAI, tais como o acesso, interceptação, eliminação, alteração, modificação ou
          manipulação, por terceiros não autorizados, do conteúdo disponibilizado pelo USUÁRIO nas
          plataformas contratadas por este e integradas pela INTEGRAI. O INTEGRAI também não será
          responsável por qualquer vírus que possa atacar o equipamento do USUÁRIO em decorrência do
          acesso, utilização ou navegação no Site.
        </p>
      </div>

      <div>
        <h4>CLÁUSULA 10ª - DA PROPRIEDADE INTELECTUAL DO INTEGRAI</h4>

        <p>
          10.1. O uso comercial da expressão "INTEGRAI" como marca, nome empresarial ou nome de
          domínio, bem como os conteúdos das telas relativas aos Serviços INTEGRAI assim como os
          programas, bancos de dados, redes e arquivos, que permitem que o USUÁRIO acesse e use sua
          conta, são propriedade da INTEGRAI e estão protegidos pelas leis e tratados internacionais
          de direito autoral, marcas, patentes, modelos e desenhos industriais. O uso indevido e a
          reprodução total ou parcial dos referidos conteúdos são proibidos, salvo quando
          autorizados expressamente pela INTEGRAI.
        </p>

        <p>
          10.2. O INTEGRAI pode utilizar links de outros sites da rede que não sejam de propriedade
          ou operados pela INTEGRAI. Nestes casos, a INTEGRAI não será responsável pelos conteúdos,
          práticas e serviços ofertados nos mesmos. A presença de links para outros sites não
          implica relação de sociedade, de supervisão, de cumplicidade ou solidariedade da INTEGRAI
          para com esses sites e seus conteúdos.
        </p>
      </div>

      <div>
        <h4>CLÁUSULA 11ª - DAS DISPOSIÇÕES GERAIS E DO FORO</h4>

        <p>
          11.1. O USUÁRIO expressamente aceita que a INTEGRAI e/ou qualquer de seus parceiros enviem
          aos seus assinantes mensagens de e-mail de caráter informativo, referentes a comunicações
          específicas inerentes aos Serviços INTEGRAI prestados sob este Termo, bem como de natureza
          comercial, incluindo, entre outras, a ofertas dos parceiros da INTEGRAI, novidades no
          Site, entre outras informações. Caso o USUÁRIO não deseje mais receber mensagens de
          natureza comercial, poderá solicitar o cancelamento pelo Site ou pelo link do próprio
          e-mail recebido, sendo que as demais mensagens somente serão suspensas com o cancelamento
          do CADASTRO do USUÁRIO na INTEGRAI.
        </p>

        <p>
          11.2. A fim de manter e atender os últimos requisitos de mercado e de desenvolvimento
          tecnológicos, a INTEGRAI poderá alterar, a seu critério, tanto em forma como em conteúdo,
          suspender ou cancelar, a seu exclusivo critério, a qualquer tempo, quaisquer dos Serviços
          INTEGRAI, produtos, utilidade ou aplicação, disponibilizados por si ou por terceiros,
          mediante prévio aviso ao USUÁRIO.
        </p>

        <p>
          11.3. A tolerância da INTEGRAI com o USUÁRIO, relativamente ao descumprimento de qualquer
          das obrigações ora assumidas, não será considerada novação ou renúncia a qualquer direito,
          constituindo mera liberalidade, que não impedirá a INTEGRAI de exigir posteriormente o
          fiel cumprimento das condições previstas neste Termo, a qualquer tempo.
        </p>

        <p>
          11.4. O USUÁRIO não poderá ceder o seu CADASTRO para terceiros ou outros USUÁRIOS. Por sua
          vez, a INTEGRAI poderá, independentemente de qualquer aviso ou notificação, ceder os
          Serviços INTEGRAI, no todo ou em parte, a empresas parceiras ou de seu grupo econômico, a
          qualquer momento.
        </p>

        <p>
          11.5. O USUÁRIO declara e concorda expressamente: a) ser maior de 18 anos e possuir
          capacidade jurídica; b) em instalar e manter atualizados programas Anti Spywares,
          Anti-vírus e outros que impeçam a violação do sistema que é utilizado para ter acesso aos
          Serviços INTEGRAI; c) não utilizar os Serviços INTEGRAI para quaisquer transações
          ilícitas; d) em manter o sigilo de sua SENHA da conta cadastrada, não os fornecendo a
          terceiros; e) em notificar imediatamente a INTEGRAI caso tome ciência de qualquer violação
          de segurança relacionada aos Serviços INTEGRAI ou uso não autorizado de seu nome de
          USUÁRIO e SENHA; f) ter condições financeiras de arcar com os pagamentos, custos e
          despesas decorrentes das transações efetuadas com a INTEGRAI; g) reconhecer que o presente
          Termo se formaliza, vinculando as partes, com a sua aceitação eletrônica pelo USUÁRIO, o
          que se fará mediante o clique no botão “Aceito os Termos de Uso”; h) que leu e está ciente
          e de pleno acordo com todos os termos e condições do presente Termo, razão pela qual o
          aceita de livre e espontânea vontade.
        </p>

        <p>
          11.6. Assim, tendo em vista as cláusulas do presente Termo, o USUÁRIO concorda
          integralmente com essas disposições, se comprometendo a respeitar as condições aqui
          previstas de forma irretratável e irrevogável, bem como a utilizar de modo proveitoso e
          legal os serviços a ele oferecidos.
        </p>

        <p>
          11.7. Todos os itens deste Termo de Uso estão regidos pelas leis vigentes na República
          Federativa do Brasil. Para todos os assuntos referentes à sua interpretação e cumprimento,
          as partes se submeterão ao Foro Central da Comarca da Capital do Estado do Rio de Janeiro,
          exceção feita a reclamações apresentadas por USUÁRIOS que se enquadrem no conceito legal
          de consumidores, que poderão submeter ao foro de seu domicílio.
        </p>
      </div>
    </div>
  </ig-page-base>
</template>

<script>
import Vue from 'vue';
import { IgPageBase } from '@integrai/ui';

export default Vue.extend({
  name: 'page-term-and-conditions',
  components: {
    IgPageBase,
  },
});
</script>

<style lang="scss">
.page-terms {
  h4 {
    margin-top: 5rem !important;
  }
  ul {
    list-style: lower-alpha;
    li {
      margin-bottom: 1rem;
    }
  }
}
</style>
